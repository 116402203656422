import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'PlanListWidget',
  props: {
    planIds: {
      type: WidgetPropertyType.STRING,
    },
  },
  methods: {},
  events: {},
});
